<template>
  <div>项目工单统计</div>
</template>

<script>
export default {
  name: 'projectOrder',
  data () {
    return {}
  }
}
</script>

<style scoped>
</style>
